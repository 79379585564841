import { buildPtusFrontendUrl } from '@repairer-frontend/ptus-frontend-url-builder';

/**
 * Base URLs for different sections of the application.
 */
export const BASE_URLS = {
    jobs: '/jobs',
    returns: '/returns',
};

/**
 * URLs for various pages in the application.
 */
export const PAGES = {
    jobs: 'jobs',
    jobdetail: 'jobdetail',
    jobDetailInstantOrder: 'jobdetail',
    reports: buildPtusFrontendUrl('Reporting/ReportBusinessPerformanceList'),
    feedback: buildPtusFrontendUrl('CompanyFeedback/FeedbackAssigned'),
    settings: buildPtusFrontendUrl('Profile/MyCompany/RepairerSettings'),
    companySummary: buildPtusFrontendUrl('Profile/MyCompany/Summary'),
    companyProfile: buildPtusFrontendUrl('Profile/MyCompany/CompanyProfile'),
    viewCompanyUsers: buildPtusFrontendUrl(
        'Profile/MyCompany/ManageCompanyUsers',
    ),
    addUser: buildPtusFrontendUrl('Profile/MyCompany/AddUser'),
    resetUserPassword: buildPtusFrontendUrl(
        'Profile/MyCompany/ResetUserPassword',
    ),
    repairerCompanySettings: buildPtusFrontendUrl(
        'Profile/MyCompany/RepairerSettings',
    ),
    supplierCompanySettings: buildPtusFrontendUrl(
        'Profile/MyCompany/SupplierSettings',
    ),
    dataSharing: buildPtusFrontendUrl('Profile/MyCompany/DataSharing'),
    invoiceAndPayments: buildPtusFrontendUrl('InvoiceAndPayments'),
    preferredSuppliers: buildPtusFrontendUrl(
        'Profile/MyCompany/PreferredSuppliers',
    ),
    preferredOemSuppliers: buildPtusFrontendUrl(
        'Profile/MyCompany/PreferredOemSuppliers/List',
    ),
    actOnBehalfAuthorizations: buildPtusFrontendUrl(
        'Profile/MyCompany/ActOnBehalf',
    ),
    registeredPiClients: buildPtusFrontendUrl('Profile/MyCompany/PIClients'),
    companyRatingComments: buildPtusFrontendUrl(
        'Profile/MyCompany/CompanyRatings/ViewRatingComments',
    ),
    companyRatingDetails: buildPtusFrontendUrl(
        'Profile/MyCompany/CompanyRatings/ViewRatingDetails',
    ),
    locationAuthorization: buildPtusFrontendUrl(
        'Profile/MyCompany/LocationAuthorization',
    ),
    manageSubCompanies: buildPtusFrontendUrl(
        'Profile/MyCompany/ManageSubCompanies',
    ),
    activity: buildPtusFrontendUrl('AllActivity'),
    returns: 'returns',
    settingsPreferredVendors: 'settings/preferred-vendors',
    activeJobs: 'jobs/active-jobs',
};

/**
 * Paths for various routes in the application.
 */
export const PATHS = {
    /**
     * job MFE: Jobs dashboard
     */
    jobs: `${BASE_URLS.jobs}/active-jobs`,
    /**
     * jobdetail MFE: List of parts page
     */
    jobdetail: `/job/:id`,
    jobdetailActionRequired: `/job/:id?status=actionRequired`,
    /**
     * returns MFE: Returns dashboard
     */
    returns: '/returns',
    /**
     * returns MFE: Returns detail page
     */
    returnsDetail: '/returns/:returnId',
    /**
     * returns MFE: Create a new return page
     */
    returnsNewWithPart: '/returns/new/:orderId/:orderPartId',

    jobDetailInstantOrder: `/job/:id/instantorder`,
    /*
     * settings MFE: Settings preferred vendor
     */
    settingsPreferredVendors: '/settings/preferred-vendors',
};
