const devHostname = 'devrepairer.partstrader.io';
const localHostname = 'localhost';

// Function to determine base URL based on environment
const getCoreSiteHostname = (hostname: string): string => {
    if (hostname === 'repairer.partstrader.us.com')
        return 'my.partstrader.us.com';
    if (hostname === devHostname) return 'dev.partstrader.io';

    // pp101 has a special vanity url for some reason
    if (hostname === 'repairer.testpartstrader.us.com') {
        return hostname.replace('repairer', 'pp101');
    }

    return hostname.replace('repairer', '');
};

export const buildPtusFrontendUrl = (path: string): string => {
    if (typeof window !== 'undefined') {
        const { protocol, hostname, port } = window.location;

        const isLocal = hostname === localHostname;
        if (isLocal) {
            return `${protocol}//${hostname}:${port}/${path}`;
        }

        const coreHostname = getCoreSiteHostname(hostname);

        // Construct the final URL with the provided path
        return `${protocol}//${coreHostname}/${path}`;
    }
    return '';
};

export const buildRepairerFrontendUrl = (path: string): string => {
    const { protocol, hostname, port } = window.location;
    const rootUrl = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

    // Construct the final URL with the provided path
    return `${rootUrl}/${path}`;
};
